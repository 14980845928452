import React from "react";

const MenuSection = () => {
  const menuItems = {
    starters: [
      { id: 1, name: "Magnam Tiste", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$5.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },
      { id: 2, name: "Aut Luia", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$14.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 3, name: "Est Eligendi", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$8.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-3.png" },
      { id: 4, name: "Eos Luibusdam", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$12.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },
      { id: 5, name: "Eos Luibusdam", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$12.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },
      { id: 6, name: "Laboriosam Direva", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$9.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },
    ],
    breakfast: [
      { id: 1, name: "Magnam Tiste", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$5.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 2, name: "Aut Luia", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$14.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 3, name: "Est Eligendi", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$8.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-3.png" },
      { id: 4, name: "Eos Luibusdam", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$12.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },  
    ],
    lunch: [
      { id: 1, name: "Magnam Tiste", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$5.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 2, name: "Aut Luia", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$14.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 3, name: "Est Eligendi", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$8.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-3.png" },
      { id: 4, name: "Eos Luibusdam", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$12.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },

    ],
    dinner: [
      { id: 1, name: "Magnam Tiste", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$5.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" },
      { id: 2, name: "Aut Luia", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$14.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-1.png" }, 
      { id: 3, name: "Est Eligendi", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$8.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-3.png" },
      { id: 4, name: "Eos Luibusdam", ingredients: "Lorem, deren, trataro, filede, nerada", price: "$12.95", image: "https://bootstrapmade.com/content/demo/Yummy/assets/img/menu/menu-item-5.png" },
    ],
  };

  return (
    <section id="menu" className="menu section">
      {/* Section Title */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Our Menu</h2>
        <p>
          <span>Check Our</span> <span className="description-title">Yummy Menu</span>
        </p>
      </div>

      <div className="container">
        <MenuTabs />
        <div className="tab-content" data-aos="fade-up" data-aos-delay="200">
          <MenuTabContent id="menu-starters" title="Starters" items={menuItems.starters} active />
          <MenuTabContent id="menu-breakfast" title="Breakfast" items={menuItems.breakfast} />
          <MenuTabContent id="menu-lunch" title="Lunch" items={menuItems.lunch} />
          <MenuTabContent id="menu-dinner" title="Dinner" items={menuItems.dinner} />
        </div>
      </div>
    </section>
  );
};

const MenuTabs = () => {
  return (
    <ul className="nav nav-tabs d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100" role="tablist">
      <li className="nav-item" role="presentation">
        <a className="nav-link active show" data-bs-toggle="tab" data-bs-target="#menu-starters" role="tab">
          <h4>Starters</h4>
        </a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-breakfast" role="tab">
          <h4>Breakfast</h4>
        </a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-lunch" role="tab">
          <h4>Lunch</h4>
        </a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" data-bs-toggle="tab" data-bs-target="#menu-dinner" role="tab">
          <h4>Dinner</h4>
        </a>
      </li>
    </ul>
  );
};

const MenuTabContent = ({ id, title, items, active = false }) => {
  return (
    <div className={`tab-pane fade ${active ? "active show" : ""}`} id={id} role="tabpanel">
      <div className="tab-header text-center">
        <p>Menu</p>
        <h3>{title}</h3>
      </div>
      <div className="row gy-5">
        {items.map((item) => (
          <MenuItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
};

const MenuItem = ({ name, ingredients, price, image }) => {
  return (
    <div className="col-lg-4 menu-item">
      <a href={image} className="glightbox">
        <img src={image} className="menu-img img-fluid" alt={name} />
      </a>
      <h4>{name}</h4>
      <p className="ingredients">{ingredients}</p>
      <p className="price">{price}</p>
    </div>
  );
};

export default MenuSection;
