import React from "react";
import { CiMedicalClipboard } from "react-icons/ci";
import { FaRegGem } from "react-icons/fa";


const About = () => {
  return (
    <section id="about" className="about section">
      {/* Section Title */}
      <div
        className="container section-title aos-init aos-animate"
        data-aos="fade-up"
      >
        <h2>About Us</h2>
        <p>
          <span>Learn More</span>{" "}
          <span className="description-title">About Us</span>
        </p>
      </div>
      {/* End Section Title */}

      <div className="container">
        <div className="row gy-4">
          {/* Left Column */}
          <div
            className="col-lg-7 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              src="https://bootstrapmade.com/content/demo/Yummy/assets/img/about.jpg"
              className="img-fluid mb-4"
              alt="About Us"
            />
            <div className="book-a-table">
              <h3>Book a Table</h3>
              <p>+1 5589 55488 55</p>
            </div>
          </div>
          {/* Right Column */}
          <div
            className="col-lg-5 aos-init aos-animate"
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <div className="content ps-0 ps-lg-5">
              <p className="fst-italic">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <ul>
                <li>
                <CiMedicalClipboard />
                  <span>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </span>
                </li>
                <li>
                  <i className="bi bi-check-circle-fill"></i>{" "}
                  <span>
                    Duis aute irure dolor in reprehenderit in voluptate velit.
                  </span>
                </li>
                <li>
                  <FaRegGem />
                  <span>
                    Ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate trideta
                    storacalaperda mastiro dolore eu fugiat nulla pariatur.
                  </span>
                </li>
              </ul>
              <p>
                Ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis
                aute irure dolor in reprehenderit in voluptate velit esse
                cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                cupidatat non proident.
              </p>

              <div className="position-relative mt-4">
                <img
                  src="https://bootstrapmade.com/content/demo/Yummy/assets/img/about-2.jpg"
                  className="img-fluid"
                  alt="About Us"
                />
                <a
                  href="https://www.youtube.com/watch?v=Y7f98aduVJ8"
                  className="glightbox pulsating-play-btn"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
