import React from "react";

const Header = () => {
  return (
    <header id="header" className="header d-flex align-items-center sticky-top">
      <div style={{textDecoration:"none"}} className="container position-relative d-flex align-items-center justify-content-between text-decoration-none">
        {/* Logo Section */}
        <a style={{textDecoration:"none"}} href="index.html" className="logo d-flex align-items-center me-auto me-xl-0">
          {/* Uncomment the line below if you also wish to use an image logo */}
          {/* <img src="assets/img/logo.png" alt="Logo" /> */}
          <h1 className="sitename">Yummy</h1>
          <span>.</span>
        </a>

        {/* Navigation Menu */}
        <nav id="navmenu" className="navmenu">
          <ul>
            <li>
              <a style={{textDecoration:"none"}} href="#hero" className="active">
                Home
              </a>
            </li>
            <li>
              <a style={{textDecoration:"none"}} href="#about">About</a>
            </li>
            <li>
              <a style={{textDecoration:"none"}} href="#menu">Menu</a>
            </li>
           
            {/* <li className="dropdown">
              <a href="#">
                <span>Dropdown</span>{" "}
                <i className="bi bi-chevron-down toggle-dropdown"></i>
              </a>
              <ul>
                <li>
                  <a href="#">Dropdown 1</a>
                </li>
                <li className="dropdown">
                  <a href="#">
                    <span>Deep Dropdown</span>{" "}
                    <i className="bi bi-chevron-down toggle-dropdown"></i>
                  </a>
                  <ul>
                    <li>
                      <a href="#">Deep Dropdown 1</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 2</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 3</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 4</a>
                    </li>
                    <li>
                      <a href="#">Deep Dropdown 5</a>
                    </li>
                  </ul>
                </li>
                <li>
                  <a href="#">Dropdown 2</a>
                </li>
                <li>
                  <a href="#">Dropdown 3</a>
                </li>
                <li>
                  <a href="#">Dropdown 4</a>
                </li>
              </ul>
            </li> */}
            <li>
              <a style={{textDecoration:"none"}} href="#contact">Contact</a>
            </li>
          </ul>
          <i className="mobile-nav-toggle d-xl-none bi bi-list"></i>
        </nav>

        {/* Call to Action Button */}
        <a style={{textDecoration:"none"}} className="btn-getstarted" href="index.html#book-a-table">
          Book a Table
        </a>
      </div>
    </header>
  );
};

export default Header;
