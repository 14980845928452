// ChefsSection.js
import React from 'react';
import TeamMember from './TeamMember';
import Container from 'react-bootstrap/Container';

const ChefsSection = () => {
  const chefs = [
    {
      name: 'William Anderson',
      role: 'Cook',
      imgSrc: 'assets/img/chefs/chefs-3.jpg',
      socialLinks: [
        { href: '#', icon: 'bi bi-twitter' },
        { href: '#', icon: 'bi bi-facebook' },
        { href: '#', icon: 'bi bi-instagram' },
        { href: '#', icon: 'bi bi-linkedin' }
      ],
      description: 'Vero omnis enim consequatur. Voluptas consectetur unde qui molestiae deserunt. Voluptates enim aut architecto porro aspernatur molestiae modi.'
    }
  ];

  return (
    <section id="hero" className="hero section light-background">

      <Container>
        <div className="row gy-4 justify-content-center justify-content-lg-between">
          <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center">
            <h1 data-aos="fade-up" className="aos-init aos-animate">Enjoy Your Healthy<br/>Delicious Food</h1>
            <p data-aos="fade-up" data-aos-delay="100" className="aos-init aos-animate">We are team of talented designers making websites with Bootstrap</p>
            <div className="d-flex aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
              <a href="#book-a-table" className="btn-get-started">Booka a Table</a>
              <a href="https://www.youtube.com/watch?v=Y7f98aduVJ8" className="glightbox btn-watch-video d-flex align-items-center"><i className="bi bi-play-circle"></i><span>Watch Video</span></a>
            </div>
          </div>
          <div className="col-lg-5 order-1 order-lg-2 hero-img aos-init aos-animate" data-aos="zoom-out">
            <img src="https://bootstrapmade.com/content/demo/Yummy/assets/img/hero-img.png" className="img-fluid animated" alt=""/>
          </div>
        </div>
      </Container>

    </section>
  );
};

export default ChefsSection;
