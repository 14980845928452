import React from "react";
import { FaInstagram, FaFacebookF, FaLinkedin, FaTwitter } from "react-icons/fa";


const Footer = () => {
  return (
    <footer id="footer" className="footer dark-background">
      <div className="container">
        <div className="row gy-3">
          <FooterItem
            icon="bi-geo-alt"
            title="Address"
            content={
              <>
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
              </>
            }
          />
          <FooterItem
            icon="bi-telephone"
            title="Contact"
            content={
              <>
                <p>
                  <strong>Phone:</strong> +1 5589 55488 55
                  <br />
                  <strong>Email:</strong> info@example.com
                </p>
              </>
            }
          />
          <FooterItem
            icon="bi-clock"
            title="Opening Hours"
            content={
              <>
                <p>
                  <strong>Mon-Sat:</strong> 11AM - 23PM
                  <br />
                  <strong>Sunday:</strong> Closed
                </p>
              </>
            }
          />
          <FollowUs />
        </div>
      </div>

      <div className="container copyright text-center mt-4">
        <p>
          © <span>Copyright</span> <strong className="px-1 sitename">Yummy</strong>{" "}
          <span>All Rights Reserved</span>
        </p>
        <div className="credits">
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
    </footer>
  );
};

// Reusable Footer Item Component
const FooterItem = ({ icon, title, content }) => {
  return (
    <div className="col-lg-3 col-md-6 d-flex">
      <i className={`bi ${icon} icon`}></i>
      <div>
        <h4>{title}</h4>
        {content}
      </div>
    </div>
  );
};

// Follow Us Component
const FollowUs = () => {
  const socialLinks = [
    { platform: "twitter", icon: <FaTwitter />, link: "#" },
    { platform: "facebook", icon: <FaFacebookF /> },
    { platform: "instagram", icon: <FaInstagram />, link: "#" },
    { platform: "linkedin", icon: <FaLinkedin />, link: "#" },
  ];

  return (
    <div className="col-lg-3 col-md-6">
      <h4>Follow Us</h4>
      <div className="social-links d-flex text-primary">
        {socialLinks.map((social, index) => (
          <a key={index} href={social.link} className={social.platform}>
            <i className={`bi ${social.icon}`}></i>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Footer;
