// App.js
import React from 'react';
import ChefsSection from './components/ChefsSection';
import BookATable from './components/boocktable';
import Gallery from './components/Gallery';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Header';
import About from './components/about';
import WhyUs from './components/whyus';
import MenuSection from './components/Gallery';


const App = () => (
  <div>
    <Header />
    <ChefsSection />
    <About />
    <WhyUs />
    <MenuSection />
    <Contact />
    <Footer />
  </div>
);

export default App;
